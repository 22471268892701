<template>
  <VNavigationDrawer v-model="drawer" class="bluegiant-red" app right temporary>
    <BaseButton
      icon
      fab
      small
      color="blue darken-4"
      md-icon="close"
      aria-label="Close Menu"
      @click="$store.commit('nav/TOGGLE_DRAWER')"
    ></BaseButton>
    <VList>
      <VListTile to="/" @click.native.stop="currPageAction">
        <VListTileContent>
          <VListTileTitle class="r-title white--text">Home</VListTileTitle>
        </VListTileContent>
      </VListTile>
      <VListTile to="/locations/blue-giant" @click.native.stop="currPageAction">
        <VListTileContent>
          <VListTileTitle class="r-title white--text">Menu</VListTileTitle>
        </VListTileContent>
      </VListTile>
      <VListTile to="/about-contact" @click.native.stop="currPageAction">
        <VListTileContent>
          <VListTileTitle class="r-title white--text"
            >About &amp; Contact</VListTileTitle
          >
        </VListTileContent>
      </VListTile>
    </VList>
    <VFlex>
      <BaseButton
        v-if="theLocation && formattedPhone(theLocation)"
        class="ml-0 mt-3"
        aria-label="Call Us Now"
        :href="`tel:${formattedPhone(theLocation)}`"
        color="blue darken-4"
        >Call Us</BaseButton
      >
    </VFlex>
    <VFlex>
      <BaseButton
        class="ml-0 mt-3"
        aria-label="Order Now"
        color="blue darken-4"
        href="https://resy.com/cities/no/blue-giant"
        >Book Now</BaseButton
      >
    </VFlex>
  </VNavigationDrawer>
</template>

<script>
import { mapGetters } from 'vuex'

import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'MobileNavBar',
  components: {},
  data() {
    return {
      showMenusDropdown: false,
    }
  },
  computed: {
    ...mapGetters('locations', ['getLocationBySlug']),
    drawer: {
      get() {
        return this.$store.state.nav.isDrawerOpen
      },
      set(val) {
        this.$store.commit('nav/SET_DRAWER', val)
      },
    }, // drawer
    theLocation() {
      return this.getLocationBySlug('blue-giant')
    }, // theLocation
  },
  beforeMount() {
    // Set the drawer to closed on load
    this.$store.commit('nav/SET_DRAWER', false)
  },
  methods: {
    formatPhone,
    formattedPhone(location) {
      if (!location) {
        return false
      }
      return formatPhone(getContactDetail(location, 'phone_number'))
    }, // formattedPhone
    currPageAction() {
      this.$store.commit('nav/SET_DRAWER', false)
    }, // currPageAction
  },
}
</script>

<style lang="scss">
@import '@design';

.nav-drawer-locations {
  a.v-list__tile {
    padding-left: 40px;
  }
}
</style>
